import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CollectEmailModal from "./CollectEmailModal";
import Footer from "../../components/Footer";
import StartupRow from "../../components/StartupRow";
import FilterView from "./FilterView";

import ListView from "./ListView";
import AccountView from "./AccountView";
import useAccount from "../../hooks/useAccount";
import { useStartups } from "../../hooks/useStartups";
import PositionsListView from "./PositionsListView";

const Home: FC = () => {
  const {
    searchResults,
    searchResultsLoading,
    search,
    searchPositions,
    searchPositionsResults,
    searchPositionsResultsLoading,
  } = useStartups();

  const [currentView, setCurrentView] = useState<"account" | "list">("list");
  const [collectEmailModalIsOpen, setCollectEmailModalIsOpen] = useState(false);

  useEffect(() => {
    searchPositions({
      query: "",
      open_roles: [],
      fundraising_stages: [],
      min_mission_score: 0,
      industry_tags: [],
      locations: [],
      am_feeling_lucky: false,
    });
  }, []);

  return (
    <div className="bg-white min-h-screen">
      <div className="px-4 py-2 mx-auto max-w-7xl sm:w-11/12 sm:p-6">
        <div className="flex justify-between items-center flex-row px-2 ">
          <CollectEmailModal
            isOpen={collectEmailModalIsOpen}
            onClose={() => setCollectEmailModalIsOpen(false)}
          />
          <span className="text-2xl flex items-center justify-center h-full">
            <h1 className="font-semibold text-slate-800 ">GoodStartups.fyi</h1>
            <span className="text-2xl text-gray-500 ml-2 italic  hidden md:flex">
              {" "}
              Top Jobs at Breakout Startups
            </span>
            {/* <p className="text-sm text-gray-500">
              Jobs at startups with transparent data
            </p> */}

            {/* Optional text can be added here */}
          </span>
          <span className="text-sm text-gray-500 underline cursor-pointer ">
            Data & Methodology
          </span>
          {/* <div>
            <button
              className=" border-emerald-800 text-emerald-800 px-4  rounded-md  hover:text-emerald-900 font-semibold  underline"
              onClick={() => setCurrentView("account")}
            >
              Account
            </button>
          </div> */}
          {/* Other elements can be added here */}
        </div>
        <span className="md:hidden">
          <span className="text-md text-gray-500 ml-2 italic ">
            Top Jobs at Breakout Startups
          </span>
        </span>
        <hr className="border-t border-gray-300 my-2" />
        <div className="mt-6">
          {currentView === "account" ? (
            <div>
              <AccountView setCurrentView={setCurrentView} />
            </div>
          ) : currentView == "list" ? (
            <div>
              {/* <FilterView search={search} /> */}

              {/* <ListView
                isLoading={searchResultsLoading}
                startups={searchResults}
                openCollectEmailModal={() => setCollectEmailModalIsOpen(true)}
              /> */}
              <FilterView search={searchPositions} />
              <PositionsListView
                isLoading={searchPositionsResultsLoading}
                listings={searchPositionsResults}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
