export default {
  BACKEND_API_ENDPOINT:
    process.env.REACT_APP_BACKEND_API_ENDPOINT ||
    "https://startupsfyi-e4kylpdyzq-uc.a.run.app",

  INDUSTRY_TAGS: [
    "AI",
    "Crypto",
    "Healthcare",
    "Cybersecurity",
    "FinTech",
    "Devtools",
    "Consumer",
    "Enterprise",
    "Industrial",
  ],
  AMPLITUDE_API_KEY:
    process.env.REACT_APP_AMPLITUDE_API_KEY ||
    "ac13f4a839743f2eff69cd8d648e309a",
};
