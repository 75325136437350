import React, { FC } from "react";

const funFacts = [
  "WhatsApp: 55 employees, $19B sale.",
  "Instagram: 13 employees, $1B acquisition.",
  "Dropbox: From idea to $10B.",
  "Snapchat declined $3B Facebook offer.",
  "Uber: Fastest to $50B valuation.",
  "Airbnb: Founded during economic crisis.",
  "Spotify: 345M users, no profits.",
  "Slack: $27M funding, zero revenue.",
  "Tesla: First Vehicle Manufacture IPO since Ford.",
  //   "Twitter: Zero revenue, $1B IPO.",
  "Google: PhD project turned giant.",
  "Apple: Garage start, trillion valuation.",
  "Microsoft: BASIC code, global empire.",
  "YouTube: Bought for $1.65B.",
  "Facebook: $104B IPO, 1B users.",
];
const LoadingIndicator: FC = () => {
  const randomFact = funFacts[Math.floor(Math.random() * funFacts.length)];
  return (
    <div className="flex justify-center items-center w-auto flex-row ">
      <div className="text-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 m-auto"></div>
        <h2 className="text-md  px-2 mt-6 text-gray-700">Loading...</h2>
        <p className="text-md px-2 text-gray-700">Dyk? {randomFact}</p>
        <style>
          {`
          .loader {
            border-top-color: #3498db;
            -webkit-animation: spinner 1s linear infinite;
            animation: spinner 1s linear infinite;
          }

          @-webkit-keyframes spinner {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }

          @keyframes spinner {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default LoadingIndicator;
