import React, { FC, useState } from "react";
import Modal from "../../components/UICore/Modal";
import TextInput from "../../components/UICore/TextInput";
import Checkbox from "../../components/UICore/Checkbox";
import Button from "../../components/UICore/Button";
import useAccount from "../../hooks/useAccount";

interface CollectEmailModalProps {
  onClose: () => void;
  isOpen: boolean;
}
const CollectEmailModal: FC<CollectEmailModalProps> = ({ onClose, isOpen }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isLookingForJob, setIsLookingForJob] = useState<boolean>(true);
  const { submitPhoneNumberForSMSUpdates } = useAccount();
  // const [showModal, setShowModal] = useState<boolean>(false);
  const submitForm = () => {
    console.log("submitting");
    submitPhoneNumberForSMSUpdates(phoneNumber, isLookingForJob);
    localStorage.setItem("goodStartupsFormSubmitted", "true");
    onClose();
  };
  let showModal = isOpen;
  if (localStorage.getItem("goodStartupsFormSubmitted") === "true") {
    // already submitted the form don't show modal
    showModal = false;
  }

  return (
    <Modal
      title={"Looking for startups?"}
      isOpen={showModal}
      onClose={onClose}
      onConfirm={submitForm}
      onConfirmText={"Submit"}
    >
      <div className="space-y-6 pt-4">
        <p className="text-gray-700 text-left">
          We'll text you know when we add more startups ! We're still in beta
          and adding new features and startups every month.
        </p>
        <TextInput
          value={phoneNumber}
          handleChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={"Email"}
          label={"Phone Number"}
        />
        {/* <Checkbox
          label={"I'm currently looking for a job"}
          isChecked={isLookingForJob}
          handleChange={(e) => setIsLookingForJob(e.target.checked)}
        /> */}
      </div>
    </Modal>
  );
};

export default CollectEmailModal;
