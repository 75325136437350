import React, { FC, useState } from "react";
import { Company, Position } from "../models";
import ImageWithDefault from "./UICore/ImageWithDefault"; // Import the component for displaying images with a default fallback
import Button from "./UICore/Button";
import GenderDistributionBar from "./GenderDistributionBar"; // Import the GenderDistributionBar component
import { FaAngleDoubleDown } from "react-icons/fa";
import InfoToolTip from "./UICore/InfoToolTip";
import Tooltip from "./UICore/Tooltip";
import { toast } from "react-toastify";
import posthog from "posthog-js";

interface PositionRowProps {
  position: Position;
  company: Company;
}

function howRecent(date: Date): string {
  // returns how recent a date is: past week, past month, past 3 months, past 6 months, past year, over a year
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays < 7) {
    return "past week";
  } else if (diffDays < 30) {
    return "past month";
  } else if (diffDays < 90) {
    return "past 3 months";
  } else if (diffDays < 180) {
    return "past 6 months";
  } else if (diffDays < 365) {
    return "past year";
  } else {
    return "over a year";
  }
}

function formatDate(date: Date | null): string {
  if (date === null) return ""; // or return some placeholder if necessary

  const month = date.getMonth() + 1; // getMonth() is zero-indexed
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

  // Ensuring month is always two digits
  const formattedMonth = month < 10 ? `0${month}` : month.toString();

  return `${formattedMonth}/${year}`;
}

const PositionRow: FC<PositionRowProps> = ({ position, company }) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOpen = () => {
    setIsOpened(!isOpened); // Add toggleOpen function
    posthog.capture("Position Row Toggled", {
      position: position.title,
      company: company.company_name,
      isOpened: !isOpened,
    });
  };
  const truncatePercentage = (percentage: number) => {
    console.log("percentage: ", percentage);
    return percentage.toFixed(1);
  };

  const renderRelevantPerson = (person: string) => {
    const split = person.split("LINKEDIN_URL: ");
    const personDescription = split[0];
    if (split.length === 1) {
      return <p className="text-gray-500">{personDescription}</p>;
    }
    const linkedInUrl = split[1];
    const personName = personDescription.split("(")[0].slice(0, -1);
    const personDetails = "(" + personDescription.split("(").slice(1).join("(");
    return (
      <p className="text-gray-500">
        <a
          href={linkedInUrl}
          className="text-blue-500 font-light underline"
          target="_blank"
          onClick={(e) => e.stopPropagation()} // Stop event propagation
        >
          {personName}
        </a>{" "}
        {personDetails}
      </p>
    );
  };

  const renderPeople = () => {
    const people = company.relevant_people || [];
    // TODO: this line is a hack to remove people with only linkedin urls. should fix this in the backend
    const peopleToRender = people.filter(
      (person) => person && person.slice(0, 14) !== "LINKEDIN_URL: "
    );
    // const visiblePeople = false ? peopleToRender : peopleToRender.slice(0, 5);
    const visiblePeople = peopleToRender;
    return visiblePeople.map((person) => (
      <p className="text-gray-500">{renderRelevantPerson(person)}</p>
    ));
  };

  const renderWebsiteLink = (website: string | null) => {
    if (website === null) return "";
    return (
      <a
        href={website}
        className="text-blue-500 font-light underline"
        target="_blank"
        onClick={(e) => e.stopPropagation()} // Stop event propagation
      >
        {website
          .replace("https://", "")
          .replace("http://", "")
          .replace("/", "")
          .replace("www.", "")}
      </a>
    );
  };
  const colorCodeChurn = (churn: number) => {
    if (churn > 20) {
      return "text-red-600";
    } else if (churn > 10) {
      return "text-yellow-600";
    } else {
      return "text-green-600";
    }
  };
  const colorCodeGrowth = (growth: number) => {
    if (growth > 5) {
      return "text-green-600";
    } else if (growth > 0) {
      return "text-yellow-600";
    } else {
      return "text-red-600";
    }
  };
  return (
    <div
      className="cursor-pointer  flex flex-col md:flex-row items-center hover:bg-gray-100 rounded-md shadow-sm border bg-white p-6 space-y-4 md:space-y-0"
      onClick={toggleOpen}
    >
      <ImageWithDefault
        key={company.company_website}
        targetSrc={`https://logo.clearbit.com/${company.company_website}`}
        fallbackSrc="/placeholder.png"
        alt={`${position.company_name}`}
        className="w-14 h-14 md:mr-4 rounded-md"
      />

      <div className="flex-grow   justify-center flex-col flex items-center md:items-start">
        <div className="flex flex-row space-x-2 p-1 ">
          {company.accolades &&
            company.accolades.length > 0 &&
            company.accolades.map((accolade) => (
              <span className="block bg-indigo-100 rounded-full px-3 py-1 text-sm font-light text-gray-700 mt-1 md:mt-0 md:ml-2">
                {accolade}
              </span>
            ))}
        </div>

        <span className="flex flex-col  md:flex-row md:space-x-2 md:space-y-0 items-center md:items-start">
          <div className="text-gray-800 font-medium">
            {company.company_name}{" "}
            <span className="hidden md:inline">{" • "} </span>
          </div>{" "}
          <div className="text-gray-700">{company.niche}</div>
        </span>
        <div className="flex md:flex-row md:space-x-2 flex-col  mt-2 md:mt-0 items-center md:items-start">
          <div className="text-gray-800 font-bold">{position.title}</div>
          <div className="text-gray-500 hidden md:inline">{" • "}</div>
          <div className="text-gray-500">{position.location}</div>
        </div>
        <div className="flex flex-wrap flex-row space-x-2">
          {company.current_funding_stage !== "NA" && (
            <div className="flex flex-row space-x-2">
              <div className="text-gray-700">
                {company.current_funding_stage}
              </div>
              <div className="text-gray-500">{" • "}</div>
            </div>
          )}

          {company.current_headcount && company.current_headcount != -1 && (
            <div className="text-gray-700">
              {company.current_headcount} employees
            </div>
          )}

          {company.employee_headcount_growth_12_months != -1 &&
            company.employee_headcount_growth_12_months != null && (
              <>
                <div className="text-gray-500">{" • "}</div>
                <div
                  className={`flex flex-row items-center ${colorCodeGrowth(
                    company.employee_headcount_growth_12_months
                  )} `}
                >
                  <span className="mr-1">
                    {truncatePercentage(
                      company.employee_headcount_growth_12_months
                    )}
                    % Growth
                  </span>
                  <InfoToolTip message="Employee growth over the past 12 months (sample of 25 employees)" />
                </div>
              </>
            )}

          {company.employee_churn_12_months != -1 &&
            company.employee_churn_12_months != null && (
              <>
                <div className="text-gray-500">{" • "}</div>
                <div
                  className={`flex flex-row items-center ${colorCodeChurn(
                    company.employee_churn_12_months
                  )} `}
                >
                  <span className="mr-1">
                    {truncatePercentage(company.employee_churn_12_months)}%
                    Churn
                  </span>
                  <InfoToolTip message="Employee churn over the past 12 months (sample of 25 employees)" />
                </div>
              </>
            )}
        </div>
        <div className="flex flex-row space-x-2">
          {company.investors && company.investors.length > 0 && (
            <div className="text-gray-500">
              <span>
                Backed by{" "}
                <span className="font-medium">
                  {company.investors.join(", ")}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-row space-x-2 w-full">
          <p className="text-gray-500 text-md w-full">
            {company.short_summary && (
              <p className="text-gray-500 text-md">
                {isOpened ? (
                  <p>
                    {company.short_summary} Learn more at{" "}
                    {renderWebsiteLink(company.company_website)}
                  </p>
                ) : (
                  company.short_summary.slice(0, 70) + "... "
                )}
              </p>
            )}

            {!isOpened && (
              <div className="flex flex-row items-center justify-center flex-1 ">
                <p className="underline text-gray-500 font-light cursor-pointer mt-2">
                  more details
                </p>
                {/* <div className=" bg-slate-100 py-3 px-12  rounded-full ">
                  <FaAngleDoubleDown className="text-gray-600 font-light" />
                </div> */}
              </div>
            )}
            {isOpened && (
              <>
                <div>
                  <p className="text-gray-800 py-2">
                    Currently hiring for{" "}
                    <p className="font-medium inline">
                      {company.open_position_names?.join(", ")}
                    </p>
                  </p>
                </div>
                {company.relevant_people &&
                  company.relevant_people.length > 0 && (
                    <div className="my-4">
                      <p className="text-gray-800 font-semibold ">Key People</p>
                      {renderPeople()}
                    </div>
                  )}
                {company.clusters && company.clusters.length > 0 && (
                  <div className="my-4">
                    <p className="text-gray-800 font-semibold my-2">
                      Employee Clusters
                    </p>
                    <div className="flex flex-row space-x-2">
                      {company.clusters.map((cluster) => (
                        <span className="block bg-indigo-100 rounded-full px-3 py-1 text-sm font-light text-gray-700 mt-1 md:mt-0 md:ml-2">
                          {cluster}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {company.gender_ratios?.overall_counts && (
                  <div>
                    <p className="text-gray-800 font-semibold">
                      Gender Breakdown
                    </p>
                    <div className="flex flex-row justify-start items-center">
                      <p className="text-gray-500 w-[200px]">Overall</p>
                      <GenderDistributionBar
                        numMen={company.gender_ratios.overall_counts.male || 0}
                        numWomen={
                          company.gender_ratios.overall_counts.female || 0
                        }
                      />
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <p className="text-gray-500 w-[200px]">Engineering</p>
                      <GenderDistributionBar
                        numMen={
                          company.gender_ratios?.engineering_counts.male || 0
                        }
                        numWomen={
                          company.gender_ratios?.engineering_counts.female || 0
                        }
                      />
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <p className="text-gray-500 w-[200px]">Leadership</p>
                      <GenderDistributionBar
                        numMen={
                          company.gender_ratios?.leadership_counts.male || 0
                        }
                        numWomen={
                          company.gender_ratios?.leadership_counts.female || 0
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {isOpened && (
              <p className="underline text-gray-600 font-light cursor-pointer text-center">
                fewer details
              </p>
            )}
          </p>
        </div>
      </div>

      <div className="flex flex-col space-y-1 items-center md:items-end">
        {position.apply_link && (
          <a href={position.apply_link || ""} target="_blank">
            <Button
              className="bg-black  text-white border-black hover:bg-gray-800 hover:border-gray-800 py-[5px] px-4 "
              handleClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                console.log("Apply Button Clicked");
                posthog.capture("Apply Button Clicked", {
                  position: position.title,
                  company: company.company_name,
                });
              }}
              type="reset"
              label="Apply"
            />
          </a>
        )}
        <p className="text-gray-400 text-sm">
          {position.last_updated
            ? `updated ${howRecent(new Date(position.last_updated))}`
            : ""}
        </p>
        {position.key_contact && position.key_contact.name !== "Unknown" && (
          <div className="flex flex-row items-center space-x-2 group">
            <div
              className="relative group"
              onClick={(e) => {
                e.preventDefault();
                if (position.key_contact?.email) {
                  navigator.clipboard.writeText(
                    position.key_contact.email || ""
                  );
                  toast.success("Email copied to clipboard");
                }
              }}
            >
              <p className="text-gray-700 font-medium underline text-center md:text-right">
                {position.key_contact?.name}
              </p>
              <div className="group-hover:flex  justify-end flex-col text-center md:text-left hidden items-center md:items-end">
                <p className="text-sm text-gray-500">
                  {position.key_contact?.title}
                </p>
                {position.key_contact?.email && (
                  <a
                    href={`mailto:${position.key_contact.email}`}
                    className="text-blue-500 font-light underline"
                  >
                    {position.key_contact?.email}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        {/* <p>ben@harvey.ai</p> */}
      </div>
    </div>
  );
};

export default PositionRow;
