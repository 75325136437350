import React, { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
interface InfoToolTipProps {
  message: string;
}

const InfoToolTip: FC<InfoToolTipProps> = ({ message }) => {
  return (
    <div className="relative group items-center justify-center -mt-2">
      <div className="absolute hidden group-hover:block w-48 px-3 py-3 mx-4 -mt-2 text-sm leading-tight text-black transform -translate-x-1/2 -translate-y-full bg-white rounded-lg shadow-lg">
        <p className="text-gray-600">{message}</p>
      </div>
      <FaInfoCircle className="text-gray-500 text-xs" />
    </div>
  );
};
export default InfoToolTip;
