import React, { FC, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Updated to useNavigate
import ToggleSwitch from "../../components/UICore/ToggleSwitch";
import Checkbox from "../../components/UICore/Checkbox";
import TextInput from "../../components/UICore/TextInput";
import Button from "../../components/UICore/Button";
import Modal from "../../components/UICore/Modal";

import { FiSearch } from "react-icons/fi";
import DropdownMenuSelect from "../../components/UICore/DropdownMenuSelect";
import Slider from "../../components/UICore/Slider";
import InfoToolTip from "../../components/UICore/InfoToolTip";
import Chip from "../../components/UICore/Chip";
import Constants from "../../constants";
import { SearchParams } from "../../services/startupService";
interface FilterViewProps {
  search: (params: SearchParams) => void;
}

const FilterView: FC<FilterViewProps> = ({ search }) => {
  const location = useLocation(); // Added useLocation
  const navigate = useNavigate(); // Updated to useNavigate

  const allStages = [
    "Pre-Seed & Seed",
    "Series A",
    "Series B",
    "Series C",
    "Later Stage",
  ];
  const allRoles = [
    "Internships",
    "Software Engineer",
    "Backend Engineer",
    "Data Scientist",
    "ML Engineer",
    "Sales",
    "Marketing",
    "Product Manager",
    "Designer",
  ];
  const allLocations = [
    "Remote",
    "San Francisco",
    "Bay Area",
    "New York",
    "Austin",
    "Chicago",
    "Los Angeles",
    "Seattle",
    "Boston",
  ];

  const isInitialMount = useRef(true);

  const [paramsChangedAndShouldSearch, setParamsChangedAndShouldSearch] =
    useState<boolean>(false);

  const [query, setQuery] = useState<string>("");

  const [minMissionScore, setMinMissionScore] = useState<number>(0.125);
  const [minGenderDiversity, setMinGenderDiversity] = useState<number>(0.125);

  const [authModalIsOpen, setAuthModalIsOpen] = useState<boolean>(true);
  const [signUpEmail, setSignUpEmail] = useState<string>("");
  const [signUpPassword, setSignUpPassword] = useState<string>("");

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([
    "ALL",
  ]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]); // Added for location selection

  const [targetWhatWorkingOn, setTargetWhatWorkingOn] = useState<string>("");
  const [filterByWhatWorkingOn, setFilterByWhatWorkingOn] =
    useState<boolean>(false);
  const [targetOpenRole, setTargetOpenRole] = useState<string>("");
  const [fundraisingStagesToInclude, setFundraisingStagesToInclude] =
    useState<string[]>(allStages);

  const [filterByFundraisingStage, setFilterByFundraisingStage] =
    useState<boolean>(false);

  const handleCheckboxChange = (stage: string) => {
    fundraisingStagesToInclude.includes(stage)
      ? setFundraisingStagesToInclude(
          fundraisingStagesToInclude.filter((s) => s !== stage)
        )
      : setFundraisingStagesToInclude([...fundraisingStagesToInclude, stage]);
  };

  const handleSearchStartups = (isFeelingLucky: boolean = false) => {
    search({
      query: query,
      open_roles: selectedRoles,
      fundraising_stages: fundraisingStagesToInclude,
      min_mission_score: minMissionScore,
      industry_tags: selectedIndustries.includes("ALL")
        ? []
        : selectedIndustries,
      locations: selectedLocations, // Include selected locations in search parameters
      am_feeling_lucky: isFeelingLucky,
    });
  };

  useEffect(() => {
    if (query !== "") {
      navigate(`?query=${encodeURIComponent(query)}`);
    } else {
      navigate("");
    }
  }, [query, navigate]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get("query");
    if (queryParam !== null) {
      const decodedQueryParam = decodeURIComponent(queryParam);
      setQuery(decodedQueryParam); // Set the query from URL
      // Perform search immediately if query is not empty
      if (decodedQueryParam !== "") {
        handleSearchStartups();
      }
    } else {
      navigate(""); // Clear query in URL if it's empty
    }
  }, [location.search]); // Depend only on the change of the URL's search part

  // Remove the useEffect that only depends on query and navigate

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearchStartups();
    }
  }, [
    minMissionScore,
    minGenderDiversity,
    selectedRoles,
    targetWhatWorkingOn,
    targetOpenRole,
    fundraisingStagesToInclude,
    selectedIndustries,
    selectedLocations, // Include selectedLocations in the dependency array
  ]);

  return (
    <div>
      <div className="space-y-4 py-2">
        <h2 className="text-xl font-semibold px-2 text-slate-800">
          Search & Filters
        </h2>
        <div className="flex-row flex space-x-2">
          <div className="w-[450px]">
            <TextInput
              value={query}
              handleChange={(e) => setQuery(e.target.value)}
              label="search"
              onEnter={() => {
                handleSearchStartups();
                setParamsChangedAndShouldSearch(false);
              }}
              // placeholder='Search by what is being built or "*" for anything'
              placeholder="Company name"
              Icon={FiSearch} // Pass the Icon component
            />
          </div>
          <div className="flex flex-row space-x-2">
            <Button
              label="Search"
              type="reset"
              className={
                paramsChangedAndShouldSearch
                  ? "bg-black  text-white border-black hover:bg-gray-800 hover:border-gray-800 py-[5px] px-4 "
                  : "bg-black  text-white border-black hover:bg-gray-800 hover:border-gray-800 py-[5px] px-4 "
              }
              handleClick={() => {
                handleSearchStartups();
                setParamsChangedAndShouldSearch(false);
              }}
            />
          </div>
        </div>

        <div className="flex-row flex space-x-2 ">
          <DropdownMenuSelect
            options={allRoles.map((role) => ({
              value: role,
              label: role,
            }))}
            selectedValues={selectedRoles}
            setSelectedValues={setSelectedRoles}
            placeholder={
              selectedRoles.length === 0
                ? "Open Jobs"
                : selectedRoles.length === allRoles.length
                ? "Any Role"
                : `${selectedRoles.join(", ").slice(0, 20)}`
            }
            width="w-[200px]"
          />
          <DropdownMenuSelect
            options={allStages.map((stage) => ({
              value: stage,
              label: stage,
            }))}
            selectedValues={fundraisingStagesToInclude}
            setSelectedValues={setFundraisingStagesToInclude}
            placeholder={
              fundraisingStagesToInclude.length === 0
                ? "Funding Stage"
                : fundraisingStagesToInclude.length === allStages.length
                ? "Any Stage"
                : `${fundraisingStagesToInclude.join(", ").slice(0, 20)}`
            }
            width="w-[220px]"
          />
          <DropdownMenuSelect
            options={allLocations.map((location) => ({
              value: location,
              label: location,
            }))}
            selectedValues={selectedLocations}
            setSelectedValues={setSelectedLocations}
            placeholder={
              selectedLocations.length === 0
                ? "Select Location"
                : `${selectedLocations.join(", ").slice(0, 20)}`
            }
            width="w-[220px]"
          />
        </div>
        <div className="flex flex-wrap flex-row max-w-[600px]">
          <Chip
            text="ALL"
            isSelected={selectedIndustries.includes("ALL")}
            onClick={() => {
              selectedIndustries.includes("ALL")
                ? setSelectedIndustries([])
                : setSelectedIndustries(["ALL"]);
            }}
            className="m-1"
          />
          {Constants.INDUSTRY_TAGS.map((industry) => (
            <Chip
              text={industry}
              isSelected={selectedIndustries.includes(industry)}
              onClick={() => {
                selectedIndustries.includes(industry)
                  ? setSelectedIndustries(
                      selectedIndustries.filter((i) => i !== industry)
                    )
                  : setSelectedIndustries([
                      ...selectedIndustries.filter((i) => i !== "ALL"),
                      industry,
                    ]);
              }}
              className="m-1"
              // handleClick={() => setQuery(industry)}
            />
          ))}
        </div>
        {/* <div className="flex-row flex space-x-2 text-sm ml-1">
          <a
            className="text-blue-800 underline cursor-pointer"
            onClick={
              () => handleSearchStartups(true) // Pass true to indicate feeling lucky
            }
          >
            I'm Feeling Lucky
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default FilterView;
