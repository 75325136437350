import React, { FC } from "react";

const Button: FC<{
  label: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
}> = ({
  label,
  handleClick,
  type = "button",
  disabled = false,
  className = "",
}) => {
  const styles = {
    submit:
      "px-3 py-1.5 bg-blue-600 text-white text-sm leading-6  font-semibold tracking-wide rounded-lg shadow hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 disabled:opacity-50",
    reset: "rounded-md", //px-3 py-1.5 bg-none text-blue-600 border-2 border-blue-400 hover:border-blue-500 hover:bg-blue-50 text-sm leading-6  font-semibold tracking-wide rounded-lg hover:shadow  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 disabled:opacity-50",
    button:
      "px-3 py-1.5 bg-blue-600 text-white text-sm leading-6  font-semibold tracking-wide rounded-lg shadow hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 disabled:opacity-50",
  };
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={`${styles[type]} ${className}`}
    >
      {label}
    </button>
  );
};

export default Button;
