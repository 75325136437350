import React, { FC } from "react";

const Footer: FC = () => {
  return (
    <footer className="inset-x-0 bottom-0 bg-white -mt-12">
      <hr className="border-t border-gray-200" />
      <div className="text-center py-4">
        <p className="text-xs text-gray-400">
          {/* <a href="/methodology" className="underline">
            methodology
          </a> */}
          {/* {" | "} */}
          daniel.longo@stanford.edu | DDL Labs | {new Date().getFullYear()}
          {/* daniel.longo@stanford.edu */}
        </p>
      </div>
    </footer>
  );
};
export default Footer;
