import constants from "../constants";

export interface SearchParams {
  query: string;
  open_roles: string[];
  fundraising_stages: string[];
  min_mission_score: number;
  industry_tags: string[];
  locations: string[];
  am_feeling_lucky?: boolean;
}

export const searchStartupPositions = async (searchParams: SearchParams) => {
  const paramsWithDefaults = {
    ...searchParams,
    am_feeling_lucky: searchParams.am_feeling_lucky ?? false,
  };
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/startups/search_positions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paramsWithDefaults),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json().then((data) => {
      console.log("data", data);
      return data.listings;
    });
  } catch (error) {
    throw error;
  }
};

export const searchStartups = async (searchParams: SearchParams) => {
  const paramsWithDefaults = {
    ...searchParams,
    am_feeling_lucky: searchParams.am_feeling_lucky ?? false,
  };
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/startups/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paramsWithDefaults),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json().then((data) => {
      console.log("data", data);
      return data.companies;
    });
  } catch (error) {
    throw error;
  }
};
