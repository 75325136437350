import React, { FC, useState } from "react";
import { Listing, Position } from "../../models";
import PositionRow from "../../components/PositionRow";
import LoadingIndicator from "../../components/UICore/LoadingIndicator";

interface PositionsListViewProps {
  isLoading: boolean;
  listings: Listing[] | null;
}

const PositionsListView: FC<PositionsListViewProps> = ({
  isLoading,
  listings,
}) => {
  console.log(
    "PositionsListView.tsx: PositionsListView: positions: ",
    listings
  );
  const [displayCount, setDisplayCount] = useState<number>(10);

  const handleShowMore = () => {
    setDisplayCount(displayCount + 10);
  };

  return (
    <div className="min-h-screen">
      <h2 className="text-xl font-semibold px-2 my-4 text-slate-800">
        Open Positions
      </h2>
      <div className="flex flex-col space-y-2 mb-20">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-12">
            <LoadingIndicator />
          </div>
        ) : (
          listings &&
          listings
            .slice(0, displayCount)
            .map((lisiting, index) => (
              <PositionRow
                key={index}
                position={lisiting.position}
                company={lisiting.company}
              />
            ))
        )}
        {!isLoading && listings && listings.length > displayCount && (
          <button onClick={handleShowMore} className="mx-auto mt-8 underline">
            Show More ({listings.length - displayCount})
          </button>
        )}
      </div>
    </div>
  );
};

export default PositionsListView;
