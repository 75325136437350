import React from "react";

// Define a type for the component props
type GenderDistributionBarProps = {
  numMen: number;
  numWomen: number;
};

const GenderDistributionBar: React.FC<GenderDistributionBarProps> = ({
  numMen,
  numWomen,
}) => {
  const total = numMen + numWomen;
  const menWidthPercent = total > 0 ? (numMen / total) * 100 : 0;
  const womenWidthPercent = total > 0 ? (numWomen / total) * 100 : 0;

  return (
    <div className="flex items-center w-full max-w-[200px] rounded-full  overflow-hidden">
      {/* Blue bar for men */}
      <div
        className="bg-sky-400 h-5 flex items-center justify-center"
        style={{ width: `${menWidthPercent}%` }}
      >
        <span className="text-xs text-white">{numMen}</span>
      </div>
      {/* Pink bar for women */}
      <div
        className="bg-pink-400 h-5 flex items-center justify-center"
        style={{ width: `${womenWidthPercent}%` }}
      >
        {womenWidthPercent > 0 && (
          <span className="text-xs text-white">{numWomen}</span>
        )}
      </div>
    </div>
  );
};

export default GenderDistributionBar;
