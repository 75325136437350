import Constants from "../constants";

const useAccount = () => {
  const submitPhoneNumberForSMSUpdates = async (
    phoneNumber: string,
    isCurrentlyLookingForJob: boolean
  ) => {
    const response = await fetch(
      `${Constants.BACKEND_API_ENDPOINT}/account/subscribeForSMSUpdates`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone_number: phoneNumber,
          is_currently_looking_for_job: isCurrentlyLookingForJob,
        }),
      }
    );
  };

  return { submitPhoneNumberForSMSUpdates };
};

export default useAccount;
