import React, { useState, FC } from "react";

interface ImageWithDefaultProps {
  targetSrc: string;
  fallbackSrc: string;
  alt: string;
  className?: string;
}

const ImageWithDefault: FC<ImageWithDefaultProps> = ({
  targetSrc,
  fallbackSrc,
  alt,
  className,
}) => {
  const [currentSrc, setCurrentSrc] = useState(targetSrc);

  const handleError = () => {
    setCurrentSrc(fallbackSrc);
  };

  return (
    <img
      src={currentSrc}
      onError={handleError}
      alt={alt}
      className={className}
    />
  );
};

export default ImageWithDefault;
