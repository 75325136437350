import React, { FC, KeyboardEvent } from "react";
import { IconType } from "react-icons"; // This is used for the icon prop type

const TextInput: FC<{
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
  type?: string;
  Icon?: IconType; // Icon component is passed in instead of a string
  password?: boolean;
  onEnter?: () => void; // Optional prop for handling Enter key press
}> = ({
  value,
  handleChange,
  label,
  placeholder = "",
  type = "text",
  Icon,
  password,
  onEnter,
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  return (
    <div className="flex flex-col relative">
      {Icon && (
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <Icon className="w-5 h-5 text-gray-500" />
        </div>
      )}
      <input
        id={label}
        type={password ? "password" : "text"}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Add key press handler
        placeholder={placeholder}
        className={`block w-full border border-gray-300 rounded-lg pl-10 pr-3 py-2.5 text-gray-700 text-sm leading-tight focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 placeholder:text-gray-500 ${
          Icon ? "pl-10" : "pl-3"
        }`}
        // Adjust left padding depending on whether there is an icon
      />
    </div>
  );
};

export default TextInput;
