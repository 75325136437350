import React, { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Methodology from "./pages/methodolgy";
import Landing from "./pages/landing";
import * as amplitude from "@amplitude/analytics-browser";
import Constants from "./constants";

amplitude.init(Constants.AMPLITUDE_API_KEY);

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/methodology" element={<Methodology />} /> */}
      </Routes>
    </BrowserRouter>
  );
};
export default App;
