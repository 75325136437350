import React, { FC } from "react";

interface Props {
  text: string;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string; // Added className prop for style customization
}

const Chip: FC<Props> = ({ text, isSelected, onClick, className }) => {
  return (
    <div
      className={`flex cursor-pointer items-center px-2 py-1 rounded-lg text-slate-600 ${
        isSelected ? "bg-indigo-200 text-slate-900" : "bg-slate-200"
      } ${className}`} // Integrated className prop into the className string
      onClick={onClick}
    >
      <span className="text-sm">{text}</span>
    </div>
  );
};

export default Chip;
