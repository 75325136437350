// DropdownMenuSelect.js
import React, { FC, useState, useRef } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { IconType } from "react-icons";

type Option = {
  value: string;
  label: string;
};

const DropdownMenuSelect: FC<{
  options: Option[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  placeholder?: string;
  Icon?: IconType;
  width: string;
}> = ({ options, selectedValues, setSelectedValues, placeholder, width }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => setIsOpen(false));

  const allValues = options.map((o) => o.value);

  const handleOptionChange = (value: string) => {
    if (value === "all") {
      setSelectedValues(
        selectedValues.length === options.length ? [] : allValues
      );
    } else {
      setSelectedValues(
        selectedValues.includes(value)
          ? selectedValues.filter((v) => v !== value)
          : [...selectedValues, value]
      );
    }
  };

  const isSelected = (value: string) => selectedValues.includes(value);
  const isAllSelected = selectedValues.length === options.length;

  return (
    <div
      ref={dropdownRef}
      className={`relative inline-block text-left ${width}`}
    >
      <button
        type="button"
        className="flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-left border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center text-gray-500 font-normal">
          {placeholder}
        </span>
        {isOpen ? (
          <FiChevronUp className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
        ) : (
          <FiChevronDown className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
        )}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white shadow-lg border border-gray-400 rounded-lg">
          <div className="flex justify-end"></div>
          <div className="py-1">
            <label className="flex items-center space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              <input
                type="checkbox"
                value="all"
                checked={isAllSelected}
                onChange={() => handleOptionChange("all")}
                className="accent-blue-600 h-4 w-4 text-blue-600 rounded border-gray-300"
              />
              <span>All</span>
            </label>
            {options.map((option) => (
              <label
                key={option.value}
                className={`flex items-center space-x-3 px-4 py-2  text-gray-700 cursor-pointer hover:text-gray-900 text-sm ${width} border-t border-gray-300`}
              >
                <input
                  type="checkbox"
                  value={option.value}
                  checked={isSelected(option.value)}
                  onChange={() => handleOptionChange(option.value)}
                  className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenuSelect;
