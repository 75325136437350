import React, { FC, useState } from "react";
import TextInput from "../../components/UICore/TextInput";
import Rating from "../../components/UICore/Rating";
import Chip from "../../components/UICore/Chip";

interface AccountViewProps {
  setCurrentView: React.Dispatch<React.SetStateAction<"account" | "list">>;
}

const profile = {
  name: "Daniel L.",
  email: "daniel.longo@stanford.edu",
};
// const previousExperience = [
//   {
//     title: "Software Engineer",
//     company: "Google",
//   },
//   {
//     title: "Software Engineer",
//     company: "Facebook",
//   },
//   {
//     title: "Software Engineer",
//     company: "Apple",
//   },
// ];

interface Experience {
  title: string;
  company: string;
}

const AccountView: FC<AccountViewProps> = ({ setCurrentView }) => {
  const [currentSetupStage, setCurrentSetupStage] = useState(0);
  const [linedinUrl, setLinkedinUrl] = useState("");
  const [ratings, setRatings] = useState<{ [key: string]: number }>({});
  const [previousExperience, setPreviousExperience] = useState<Experience[]>(
    []
  );
  const [roles, setRoles] = useState<string[]>([
    "Software Engineer",
    "PM",
    "Designer",
    "Sales",
    "Marketing",
    "Data Scientist",
  ]);
  const [roleSelected, setRoleSelected] = useState<boolean[]>([]);

  const [previousExperinceIsLoading, setPreviousExperienceIsLoading] =
    useState(false);

  const fetchPreviousExperience = async () => {
    setPreviousExperienceIsLoading(true);
    // fetch previous experience from linkedin
    const endpoint = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/startups/previousExperience`;
    console.log(JSON.stringify({ linkedin_url: linedinUrl }));
    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ linkedin_url: linedinUrl }),
    });
    const data = await response.json();
    setPreviousExperience(data.experiences);
    setPreviousExperienceIsLoading(false);
    if (data.experiences) {
      setRatings(
        data.experiences.reduce(
          (acc: { [key: string]: number }, curr: Experience) => {
            acc[curr.company] = 5;
            return acc;
          },
          {}
        )
      );
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <button
          onClick={() => setCurrentView("list")}
          className="border-emerald-800 text-emerald-800  rounded-md  hover:text-emerald-900   underline"
        >
          Back to list
        </button>
      </div>
      <div className="flex flex-col space-y-2 mt-8">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-xl font-semibold">Account</h2>
        </div>
        <div className="flex items-center space-x-2">
          <img
            src="https://via.placeholder.com/150"
            alt="Avatar"
            className="w-12 h-12 rounded-full"
          />
          <div>
            <h3 className="text-lg font-semibold">{profile.name}</h3>
            <p className="text-sm text-gray-500">{profile.email}</p>
          </div>
        </div>
        {currentSetupStage === -1 && (
          <a href="#" className="text-sm text-emerald-800 underline">
            Edit experience
          </a>
        )}

        {currentSetupStage != -1 && (
          <div className="flex flex-col shadow-lg border bg-slate-50 w-[700px] ml-auto mr-auto rounded-lg items-center p-12 h-[450px]">
            <p className="text-xl text-emerald-700 font-semibold">
              Help Us Help You Find the Best Position
            </p>
            {currentSetupStage === 0 ? (
              // ask for linkedin url
              <div className="flex flex-col space-y-4 justify-center mb-12   h-full">
                <p className="text-md text-gray-500 px-4 ">
                  Let's start with your previous experience
                </p>
                <TextInput
                  label="LinkedIn URL"
                  value={linedinUrl}
                  handleChange={(e) => setLinkedinUrl(e.target.value)}
                  placeholder="https://www.linkedin.com/in/john-doe/"
                />
                <button
                  onClick={() => {
                    fetchPreviousExperience();
                    setCurrentSetupStage(1);
                  }}
                  className="bg-emerald-800 text-white px-4 py-2 rounded-md hover:bg-emerald-900"
                >
                  Next
                </button>
              </div>
            ) : currentSetupStage === 1 ? (
              // ask for how they liked previous roles
              <div className="flex flex-col space-y-4 justify-center mb-12   h-full">
                <p className="text-md text-gray-500 pt-4 px-4 ">
                  How did you like your previous roles?
                </p>
                <div className="flex flex-col space-y-4">
                  {previousExperinceIsLoading ? (
                    <p>Loading...</p>
                  ) : (
                    previousExperience.map((experience) => (
                      <div className="flex flex-row space-x-4 space-y-2 items-center">
                        <p className="text-md text-gray-800 px-4 w-[300px]">
                          {experience.title} at {experience.company}
                        </p>
                        <Rating
                          max={5}
                          min={1}
                          rating={ratings[experience.company]}
                          setRating={(value) =>
                            setRatings({
                              ...ratings,
                              [experience.company]: value,
                            })
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
                <button
                  onClick={() => setCurrentSetupStage(2)}
                  className="bg-emerald-800 text-white px-4 py-2 rounded-md hover:bg-emerald-90"
                >
                  Next
                </button>
              </div>
            ) : currentSetupStage === 2 ? (
              // ask for what positinos they're looking for
              <div className="flex flex-col space-y-4 justify-center mb-12   h-full">
                <p className="text-md text-gray-500 px-4 ">
                  What positions are you looking for?
                </p>
                <div className="flex flex-row space-x-4 space-y-2 items-center">
                  {roles.map((role) => (
                    <Chip
                      text={role}
                      isSelected={roleSelected[roles.indexOf(role)]}
                      onClick={() => {
                        setRoleSelected([
                          ...roleSelected.slice(0, roles.indexOf(role)),
                          !roleSelected[roles.indexOf(role)],
                          ...roleSelected.slice(roles.indexOf(role) + 1),
                        ]);
                      }}
                    />
                  ))}
                </div>

                <button
                  onClick={() => setCurrentSetupStage(3)}
                  className="bg-emerald-800 text-white px-4 py-2 rounded-md hover:bg-emerald-90"
                >
                  Next
                </button>
              </div>
            ) : currentSetupStage === 3 ? (
              // what is important to you
              <div className="flex flex-col space-y-4 justify-center mb-12   h-full">
                <p className="text-md text-gray-500 px-4 ">
                  What is important to you?
                </p>
                <div className="space-x-4 space-y-2 items-center flex flex-col">
                  <p className="text-md text-gray-800 px-4 w-[300px]">
                    Company Mission
                  </p>
                  <Rating
                    max={5}
                    min={1}
                    rating={4}
                    setRating={(value) => {}}
                  />
                  <p className="text-md text-gray-800 px-4 w-[300px]">
                    9-5 (1) vs grindset (5)
                  </p>
                  <Rating
                    max={5}
                    min={1}
                    rating={4}
                    setRating={(value) => {}}
                  />
                  <p className="text-md text-gray-800 px-4 w-[300px]">
                    Gender Diversity
                  </p>
                  <Rating
                    max={5}
                    min={1}
                    rating={4}
                    setRating={(value) => {}}
                  />
                </div>
                <button
                  onClick={() => setCurrentSetupStage(4)}
                  className="bg-emerald-800 text-white px-4 py-2 rounded-md hover:bg-emerald-90"
                >
                  Next
                </button>
              </div>
            ) : currentSetupStage === 4 ? (
              // thank you for submitting can close window
              <div className="flex flex-col space-y-4 justify-center mb-12   h-full">
                <p className="text-md text-gray-500 px-4 ">
                  Thank you for submitting!
                </p>
                <button
                  onClick={() => setCurrentSetupStage(-1)}
                  className="bg-emerald-800 text-white px-4 py-2 rounded-md hover:bg-emerald-90"
                >
                  Close
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountView;
