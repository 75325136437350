import React, { FC } from "react";

interface RatingProps {
  max: number;
  min: number;
  setRating: (rating: number) => void;
  rating: number;
}
const Rating: FC<RatingProps> = ({ max, min, setRating, rating }) => {
  return (
    <div className="flex flex-col">
      <div className="flex space-x-1">
        {Array.from(Array(max - min + 1).keys()).map((i) => (
          <button
            key={i}
            className={`border border-gray-300 rounded-md px-2 py-1 ${
              rating === i + min ? "bg-gray-200" : ""
            }`}
            onClick={() => setRating(i + min)}
          >
            {i + min}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Rating;
