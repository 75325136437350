// src/hooks/useStartup.tsx

import { useState, useCallback } from "react";
import {
  searchStartups,
  searchStartupPositions,
} from "../services/startupService";
import { SearchParams } from "../services/startupService";
import { toast } from "react-toastify";

export const useStartups = () => {
  const [searchResults, setSearchResults] = useState(null);
  const [searchPositionsResults, setSearchPositionsResults] = useState(null);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);
  const [searchPositionsResultsLoading, setSearchPositionsResultsLoading] =
    useState(false);

  const searchPositions = useCallback(async (params: SearchParams) => {
    setSearchPositionsResultsLoading(true);
    setSearchPositionsResults(null);

    try {
      const result = await searchStartupPositions(params);
      console.log("result", result);
      setSearchPositionsResults(result);
      setSearchPositionsResultsLoading(false);
    } catch (error) {
      toast.error(`Error: ${error}`);
      setSearchPositionsResultsLoading(false);
    }
  }, []);
  const search = useCallback(async (params: SearchParams) => {
    setSearchResultsLoading(true);
    setSearchResults(null);

    try {
      const result = await searchStartups(params);
      console.log("result", result);
      setSearchResults(result);
      setSearchResultsLoading(false);
    } catch (error) {
      toast.error(`Error: ${error}`);
      setSearchResultsLoading(false);
    }
  }, []);

  return {
    searchResults,
    searchResultsLoading,
    search,
    searchPositions,
    searchPositionsResults,
    searchPositionsResultsLoading,
  };
};
